import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Configure, InstantSearch} from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import {useStores} from '../../contexts/StoresContext';
import {useSession} from '../../contexts/SessionContext';
import {usePageStore} from '../../contexts/PageContext';
import {useSearchAlgoliaStore} from '../../contexts/SearchAlgoliaContext';
import {useMapAlgoliaStore} from '../../contexts/MapAlgoliaContext';
import {useSearchLocationApi} from '../../hooks/search-location';
import {DEFAULT_CENTER, DEFAULT_ZOOM, CITY_ZOOM_LEVEL} from './constants';
import {
  SearchFiltersPanel,
  SearchInput,
  SearchItems,
  SearchTabs,
} from './components';
import styles from './SearchPage.module.scss';

const SearchPage = ({currentIssue}) => {
  const {t} = useTranslation('search-page');
  const stores = useStores();
  const [session] = useSession();
  const {user, language, algolia, clientData, organization} = stores.app;
  const {locationByIp} = session;
  const [, searchActions] = useSearchAlgoliaStore();
  const [, mapAlgoliaActions] = useMapAlgoliaStore();
  const [pageState] = usePageStore();
  const searchLocationApi = useSearchLocationApi();

  const client = algoliasearch(
    window.ALGOLIA_APPLICATION_ID,
    algolia.search_api_key,
  );

  const languageSearch = (user ? user.language.code : language).split('-')[0];

  // this will calculate the location by default when an user enter in the search page or in the map view
  useEffect(() => {
    const currentIssueLocation = currentIssue?.location;
    const organizationLocation = organization?.location;
    const ipLocation = locationByIp?.location;
    const defaultZoom = clientData.map_default_zoom || DEFAULT_ZOOM;
    const defaultLocation =
      clientData.map_default_location?.location || DEFAULT_CENTER;

    const handleChangeLocation = async (
      center,
      zoom,
      place,
      searchByLatLng,
    ) => {
      let locationsArray;
      if (searchByLatLng) {
        const {results} = await searchLocationApi.searchByLatLng(
          place.lat,
          place.lng,
        );
        locationsArray = results;
      } else {
        locationsArray = await searchLocationApi.search(place);
      }
      mapAlgoliaActions.setCenterZoom(center, zoom);
      const location = await searchLocationApi.getPlace(
        locationsArray[0].place_id,
      );
      locationsArray[0].place = {
        geometry: location.geometry,
        country: null,
      };
      const addressComponent = location.address_components.filter((c) =>
        c.types.includes('country'),
      );

      if (addressComponent) {
        locationsArray[0].place.country = addressComponent[0].short_name;
      }
      searchActions.setCurrentPlace(locationsArray[0]);
    };

    // To calculate the location we will evaluate:
    // 1- if the user is searching for a particular issue
    // 2- if the organization of the user has location
    // 3- the user's IP
    // 4- If we cannot obtain this data, we will set a default location
    if (!currentIssueLocation?.place) {
      if (!organizationLocation?.location) {
        if (!ipLocation) {
          return handleChangeLocation(
            defaultLocation,
            defaultZoom,
            defaultLocation,
            true,
          );
        }
        const [lat, lng] = ipLocation.split(',');
        const coordenates = {lat: parseFloat(lat), lng: parseFloat(lng)};
        return handleChangeLocation(
          coordenates,
          defaultZoom,
          ipLocation.city,
          true,
        );
      }
      const [lat, lng] = organizationLocation.location.split(',');
      const coordenates = {lat: parseFloat(lat), lng: parseFloat(lng)};
      const zoom = organizationLocation.city ? CITY_ZOOM_LEVEL : defaultZoom;
      return handleChangeLocation(coordenates, zoom, organizationLocation.city);
    }
    const [lat, lng] = currentIssueLocation.location.split(',');
    const coordenates = {lat: parseFloat(lat), lng: parseFloat(lng)};
    handleChangeLocation(coordenates, defaultZoom, currentIssueLocation.place);
  }, [
    currentIssue,
    clientData,
    organization,
    locationByIp,
    mapAlgoliaActions,
    searchActions,
    searchLocationApi,
  ]);

  useEffect(() => {
    if (currentIssue) {
      searchActions.setCurrentIssue(currentIssue);
      return;
    }
    searchActions.setCurrentIssue(null);
  }, [searchActions, currentIssue]);

  return (
    <InstantSearch
      searchClient={client}
      stalledSearchDelay={500}
      indexName={algolia.search_index}
      insights={true}>
      <Configure
        hitsPerPage={9}
        queryLanguages={[languageSearch]}
        facetFilters={[['model:needslist_item.Item']]}
        restrictSearchableAttributes={[`title.${language}`]}
      />
      <div className={styles.wrapper}>
        {!pageState.isPublic && (
          <>
            <h2>{t('title')}</h2>
            <SearchInput currentIssue={currentIssue} />
          </>
        )}
        <SearchTabs />
      </div>
      <div className={styles.itemsWrapper}>
        <SearchFiltersPanel />
        <SearchItems />
      </div>
    </InstantSearch>
  );
};

export default SearchPage;
